<template>
  <div class="grid grid-cols-8 gap-3">
    <ul class="divide-y divide-gray-200 bg-white shadow overflow-hidden sm:rounded-md col-span-2">
      <li v-for="drop in dropStore.drops">
        <a href="#"
           class="block hover:bg-gray-50"
           :class="{'bg-gray-100': activeDrop === drop}"
           @click.prevent="activeDrop = drop">
          <div class="flex items-center px-4 py-4 sm:px-6">
            <div class="min-w-0 flex-1 flex items-center">
              <div class="min-w-0 flex-1 pr-4 gap-4">
                <div>
                  <p class="text-sm font-medium text-indigo-600 truncate"> {{ drop.name }} </p>
                  <p class="mt-2 flex items-center text-sm text-gray-500">
                    <span class="truncate">{{ drop.items.length }} Editions</span>
                  </p>
                </div>
              </div>
            </div>

            <chevron-right-icon class="w-5 h-5 text-gray-400"/>
          </div>
        </a>
      </li>
    </ul>

    <div class="sm:rounded-md col-span-3 bg-white shadow">

      <div class="flex h-full w-full items-center justify-center" v-if="!activeDrop">
        <div class="text-gray-400">Select a drop from the left column</div>
      </div>

      <item-list v-else
                 v-model="selectedItems"
                 :title="activeDrop.name"
                 :drop-items="activeDrop.items"/>
    </div>

    <div class="sm:rounded-md col-span-3 bg-white shadow">
      <template v-if="activeDrop">
        <div class="flex h-full w-full items-center justify-center" v-if="!selectedItems.length">
          <div class="text-gray-400">Select some items from the middle column</div>
        </div>
        <div v-else>
          <partner-contract-search-list
              v-model="selectedContracts"
              :drop="activeDrop"
              :item-ids="selectedItems"/>
        </div>
      </template>

    </div>

  </div>
</template>

<script setup lang="ts">

import {onMounted, ref, watch} from "vue";
import {DropsAPI} from "/js/services/Drops";
import {ChevronRightIcon} from "@heroicons/vue/20/solid";
import ItemList from "/js/components/drop_matches/ItemList.vue";
import {Drop} from "/js/models/Drop";
import {dropStore, replaceDrops} from "/js/stores/DropStore";
import {useToast} from "vue-toastification";
const toast = useToast()
import getErrorMessage from "/js/composables/getErrorMessage";
import PartnerContractSearchList from "/js/components/drop_matches/PartnerContractSearchList.vue";

const loading = ref(false)
const error = ref(null);
const activeDrop = ref(null as Drop | null);
const selectedItems = ref([] as number[])
const selectedContracts = ref([] as number[])

watch(activeDrop, (drop) => {
  selectedItems.value = []
  selectedContracts.value = []
})

async function fetchDrops() {
  loading.value = true;

  try {
    let results = await DropsAPI.getDrops()
    replaceDrops(results)
  } catch (err) {
    toast.error(getErrorMessage(err))
  }

  loading.value = false;
}

onMounted(() => {
  fetchDrops()
});

</script>