<template>
  <div class="flex-1 flex flex-col p-8">
    <h3 class="mt-6 text-gray-900 text-sm font-medium">
      {{ taskTitle }}
    </h3>

    <dl class="mt-1 flex-grow flex flex-col justify-between">

      <dd class="text-gray-500 text-sm">
        Never Ran
      </dd>

      <dd class="mt-3 flex justify-center">
        <div class="px-2 py-1 text-xs font-medium rounded-full flex items-center gap-2 bg-gray-100 text-gray-800">
          Status Unavailable
        </div>
      </dd>

      <dd class="mt-3 flex justify-center">

      </dd>

    </dl>
  </div>
</template>

<script setup lang="ts">
import {toTitleCase} from "/js/composables/useTitleCase";

const props = defineProps({
  task_type: {
    type: String,
    required: true
  }
})

const taskTitle = (str) => {
  return toTitleCase(str.split("_")[1])
}

</script>