<template>
  <div class="flex-1 flex flex-col p-8">
    <h3 class="mt-6 text-gray-900 text-sm font-medium">
      {{ taskTitle }}
    </h3>

    <dl class="mt-1 flex-grow flex flex-col justify-between">

      <dd class="text-gray-500 text-sm">
        {{ statusText }}
      </dd>

      <dd class="mt-3 flex justify-center">
        <div class="px-2 py-1 text-xs font-medium rounded-full flex items-center gap-2"
             :class="statusCssClass">
          <div>{{ taskStatus }}</div>
          <div v-if="task.status === 'running'"
               class="w-2 h-2 border-b-2 border-blue-900 rounded-full animate-spin"/>
        </div>
      </dd>

      <dd class="mt-3 flex justify-center">
        <b-button variant="primary" @click="runSync" :disabled="syncInProgress || isSyncing">
          <template v-if="isSyncing">Syncing</template>
          <template v-else>Sync</template>
        </b-button>
      </dd>

    </dl>
  </div>
</template>

<script setup lang="ts">
import {computed, PropType} from "vue";
import {BackgroundTask} from "/js/models/BackgroundTask";
import {toTitleCase} from "/js/composables/useTitleCase";
import BButton from "/js/components/forms/BButton.vue";
import {BackgroundTasksAPI} from "/js/services/BackgroundTasks";

const props = defineProps({
  task: {
    type: Object as PropType<BackgroundTask>,
    required: true
  },
  syncInProgress: {
    type: Boolean,
    default: false
  },
  modelValue: Boolean
})

const emit = defineEmits(['update:modelValue'])

const isSyncing = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})

const taskTitle = computed(() => {
  return toTitleCase(props.task.task_type.split("_")[1])
})

const statusText = computed(() => {
  if (props.task.status === 'running') {
    return `Started at ${props.task.started_at}`
  } else if (props.task.status === 'error' && props.task.message) {
    return `Error: ${props.task.message}`
  } else if (props.task.ended_at) {
    return `Last finished at ${props.task.ended_at}`
  } else {
    return "Never ran"
  }
})

const statusCssClass = computed(() => {
  const task = props.task

  if (task.status === 'queued') {
    return 'bg-emerald-100 text-emerald-800'
  } else if (task.status === 'running') {
    return 'bg-blue-100 text-blue-800'
  } else if (task.status === 'success') {
    return 'bg-green-100 text-green-800'
  } else if (task.status === 'error') {
    return 'bg-red-100 text-red-800'
  }

  return 'bg-gray-100 text-gray-800'
})

const taskStatus = computed(() => {
  return toTitleCase(props.task.status)
})

const runSync = async () => {
  isSyncing.value = true
  await BackgroundTasksAPI.createSync(props.task.task_type)
}

</script>