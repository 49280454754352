<template>
  <div>
    <modal v-model="loadReportsModal">
      <template #title>
        Load Reports
      </template>

      <report-loader @select-report="selectedLoadReport"/>
    </modal>

    <div class="grid grid-cols-8 gap-3 flex h-[calc(100vh-110px)]">
      <div class="col-span-3 rounded-md bg-white shadow overscroll-contain overflow-y-auto">
        <div class="p-3 flex flex-col gap-2 sticky top-0 bg-white">
          <div class=" flex flex-col gap-3">

            <b-button variant="outline" class="self-end" @click="loadReportsModal = true">
              Load Older Reports
            </b-button>

            <div class="flex items-end gap-3">
              <b-form-input class="grow" v-model="partnerQuery" placeholder="Search" label="App Partners"/>
              <b-button variant="primary" :disabled="!partnerQuery.length" @click="fetchPartnersByQuery">
                <magnifying-glass-icon class="w-6 h-6"/>
              </b-button>
            </div>

            <div class="flex items-center gap-3">
              <date-range-picker v-model="dateQuery"
                                 class="grow"
                                 picker-type="daterangepicker"/>

              <b-button variant="primary" :disabled="!dateQuery" @click="fetchPartnersByDate">
                <magnifying-glass-icon class="w-6 h-6"/>
              </b-button>
            </div>

            <div class="flex-1 flex gap-2 flex-wrap justify-between">
              <b-button :variant="isSelected(opt) ? 'primary' : 'outline'" v-for="opt in monthOptions"
                        @click="selectMonthInterval(opt)">
                {{ opt.text }}
              </b-button>
            </div>

            <div class="flex-1 flex gap-2 flex-wrap justify-between">
              <b-button :variant="isSelected(opt) ? 'primary' : 'outline'" v-for="opt in fullMonthOptions"
                        @click="selectMonthInterval(opt)">
                {{ opt.text }}
              </b-button>
            </div>

            <hr>

            <div class="flex gap-2">
              <b-button class="flex gap-2" :variant="onlyWithBillVendor ? 'primary' : 'outline'"
                        @click="onlyWithBillVendor = !onlyWithBillVendor">
                <check-circle-icon class="w-4 h-4" v-if="onlyWithBillVendor"/>
                <span>Bill Vendor</span>
              </b-button>

              <div class="grow"></div>

              <b-button v-for="type in saleTypes" class="flex gap-2"
                        :variant="partnerAvailableSalesType === type ? 'primary' : 'outline'"
                        @click="partnerAvailableSalesType = type">
                <check-circle-icon class="w-4 h-4" v-if="partnerAvailableSalesType === type"/>
                <span>{{ exportTypeTitle(type) }}</span>
              </b-button>

            </div>

            <hr>

            <div v-if="filteringByString">{{ filteringByString }}</div>

            <div class="flex gap-3">
              <b-button variant="primary" v-if="partners.length" @click="selectAllSearchPartners">
                Select All
              </b-button>
              <b-button variant="primary" v-if="hasSelectedSearchPartners" @click="unselectAllSearchPartners">
                Unselect All
              </b-button>
              <div class="grow"></div>
              <b-button variant="primary" v-if="hasSelectedSearchPartners" @click="addSearchPartnersToSelection">
                Add Selected
              </b-button>
            </div>

            <ul class="divide-y divide-gray-200">
              <li v-for="partner in partners">
                <div class="block hover:bg-gray-50">
                  <div class="flex items-center p-4 gap-3">
                    <b-form-checkbox v-model="selectedSearchPartners[partner.id]"/>
                    <p class="text-sm font-medium text-indigo-600 truncate grow"> {{ partner.name }} </p>
                    <b-button variant="primary" @click="addPartnerToSelection(partner)">
                      <chevron-right-icon class="w-4 h-4"/>
                    </b-button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-span-4 rounded-md bg-white shadow overscroll-contain overflow-y-auto flex flex-col gap-3 p-3">
        <div class="flex justify-between items-center">
          <div class="text-sm font-medium text-gray-700">Partner Selection</div>
          <div class="flex gap-2 items-center text-sm">
            Toggle
            <b-button variant="outline"
                      v-for="type in exportingTypes"
                      @click="toggleAll(type)"
                      :disabled="!partnerWithDates.length">
              {{ startCase(type) }}
            </b-button>
          </div>
        </div>

        <hr>

        <div v-for="obj in partnerWithDates" :key="obj.partner.id"
             class="flex items-center gap-3 hover:bg-gray-50 p-2 rounded">
          <b-button variant="danger" @click="removeSelectedPartner(obj)">
            <trash-icon class="w-4 h-4"/>
          </b-button>

          <div>{{ obj.partner.name }}</div>

          <div class="grow"></div>

          <date-range-picker v-model="obj.range"
                             class="w-52"
                             :error-highlight="!obj.range"
                             picker-type="daterangepicker"/>

          <div class="grid grid-cols-2 gap-2">
            <b-form-checkbox v-model="obj.sale">Sales</b-form-checkbox>
            <b-form-checkbox v-model="obj.claim">Claims</b-form-checkbox>
            <b-form-checkbox v-model="obj.combined">Combined</b-form-checkbox>
            <b-form-checkbox v-model="obj.split_combined">Combined - Split</b-form-checkbox>
          </div>

        </div>
      </div>
      <div class="col-span-1 rounded-md bg-white shadow overscroll-contain overflow-y-auto flex flex-col gap-3 p-3">
        <div class="text-sm font-medium text-gray-700">
          Export
          <template v-if="partnerWithDates.length">{{ partnersParams.length }} reports</template>
        </div>

        <div class="flex gap-3">
          <b-button variant="primary" :disabled="!validExport || exporting" @click="exportReport">
            <div class="flex gap-2 items-center">
              <template v-if="exporting">
                <span>Exporting</span>
                <div class="w-3 h-3 border-b-2 border-white rounded-full animate-spin"></div>
              </template>
              <template v-else>
                Export
              </template>
            </div>
          </b-button>
        </div>

        <div class="py-3 flex flex-col gap-2">
          <a class="btn-outline flex gap-1 items-center" :href="report.url" target="_blank"
             v-for="report in generatedReports">
            <div class="whitespace-nowrap">Report {{ report.id }}</div>
            <arrow-top-right-on-square-icon class="w-4 h-4"/>
          </a>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup lang="ts">

import BFormInput from "/js/components/forms/BFormInput.vue";
import {computed, ref, watch} from "vue";
import DateRangePicker from "/js/components/forms/DateRangePicker.vue";
import BButton from "/js/components/forms/BButton.vue";
import {
  MagnifyingGlassIcon,
  ChevronRightIcon,
  TrashIcon,
  ArrowTopRightOnSquareIcon,
  CheckBadgeIcon,
  CheckCircleIcon
} from "@heroicons/vue/24/solid";
import {PartnersAPI} from "/js/services/Partners";
import getErrorMessage from "/js/composables/getErrorMessage";
import {useToast} from "vue-toastification";
import {watchDebounced} from "@vueuse/core";
import Spinner from "/js/components/Spinner.vue";
import {Partner} from "/js/models/Partner";
import BFormCheckbox from "/js/components/forms/BFormCheckbox.vue";
import {ReportsAPI, PartnerWithDate, Report} from "/js/services/Reports";
import consumer from "/channels/consumer";
// @ts-ignore
import dayjs from "dayjs/esm/index.js"
//@ts-ignore
import { startCase } from "lodash";
import Modal from "/js/components/Modal.vue";
import ReportLoader from "/js/components/sales_exports/ReportLoader.vue";

const toast = useToast()

const partnerQuery = ref("")
const dateQuery = ref(null)
const partners = ref([] as Partner[])
const partnerWithDates = ref([] as PartnerWithDate[])

const loadReportsModal = ref(false)

const onlyWithBillVendor = ref(false)
const partnerAvailableSalesType = ref('sale')

const generatedReports = ref([] as { id: number, url: string }[])

const selectedSearchPartners = ref({} as { [key: number]: boolean })
const saleTypes = ['sale', 'claim', 'combined']
const exportingTypes = [...saleTypes, 'split_combined']

const exporting = ref(false)

type FilteringByType = 'partner' | 'date'

const filteringBy = ref<FilteringByType>('partner')
const filteringByDate = ref("")

const filteringByString = computed(() => {
  if (partners.value.length === 0) {
    return null
  }

  if (filteringBy.value === 'partner') {
    return "Filtering by partner name"
  } else if (filteringBy.value === 'date') {
    return `Filtering by date range ${filteringByDate.value}`
  }

  return null
})

const fetchPartnersByQuery = async () => {
  if (!partnerQuery.value || partnerQuery.value.length === 0) {
    return
  }

  try {
    partners.value = await PartnersAPI.getPartners(partnerQuery.value)
    filteringBy.value = 'partner'
  } catch (err) {
    toast.error(getErrorMessage(err))
  }
}

const fetchPartnersByDate = async () => {
  if (!dateQuery.value || dateQuery.value.length === 0) {
    return
  }

  try {
    partners.value = await PartnersAPI.getPartnersWithAvailableSales(dateQuery.value, partnerAvailableSalesType.value, onlyWithBillVendor.value)
    filteringByDate.value = dateQuery.value
    filteringBy.value = 'date'
  } catch (err) {
    toast.error(getErrorMessage(err))
  }
}

watchDebounced(partnerQuery, fetchPartnersByQuery, {debounce: 300})
watch(onlyWithBillVendor, fetchPartnersByDate)
watch(partnerAvailableSalesType, fetchPartnersByDate)

const selectAllSearchPartners = () => {
  selectedSearchPartners.value = {}
  partners.value.forEach(p => selectedSearchPartners.value[p.id] = true)
}

const unselectAllSearchPartners = () => {
  selectedSearchPartners.value = {}
}

const hasSelectedSearchPartners = computed(() => {
  return selectedPartners.value.length > 0
})

const selectedPartners = computed(() => {
  return partners.value.filter(partner => selectedSearchPartners.value[partner.id])
})

const addPartnerToSelection = (partner: Partner) => {
  const currentIds = partnerWithDatesIds.value
  if (currentIds.includes(partner.id)) {
    return
  }

  partnerWithDates.value.push({
    partner: partner,
    range: dateQuery.value,
    balance: false,
    combined: partnerAvailableSalesType.value === 'combined',
    claim: partnerAvailableSalesType.value === 'claim',
    sale: partnerAvailableSalesType.value === 'sale',
    split_combined: false,
  })
}

const partnerWithDatesIds = computed(() => {
  return partnerWithDates.value.map(pd => pd.partner.id)
})

const addSearchPartnersToSelection = () => {
  selectedPartners.value.forEach(p => addPartnerToSelection(p))
}

const removeSelectedPartner = (partner: PartnerWithDate) => {
  const index = partnerWithDates.value.indexOf(partner)
  if (index > -1) {
    partnerWithDates.value.splice(index, 1)
  }
}

const exportTypeTitle = (type) => {
  switch (type) {
    case "sale":
      return "Sales"
    case "claim":
      return "Claims"
    case "combined":
      return "Combined"
  }
}

const exportReport = async () => {
  exporting.value = true
  try {
    let obj = await ReportsAPI.createSalesExport(partnersParams.value)
    subscribeToSocket(obj.id)
  } catch (err) {
    exporting.value = false
    toast.error(getErrorMessage(err))
  }
}

const partnersParams = computed(() => {
  const sales = partnerWithDates.value.filter(p => p.sale)
  const claims = partnerWithDates.value.filter(p => p.claim)
  const combined = partnerWithDates.value.filter(p => p.combined)
  const splitCombined = partnerWithDates.value.filter(p => p.split_combined)

  return [
    ...sales.map(p => ({partner_id: p.partner.id, range: p.range, sale_type: 'sale'})),
    ...claims.map(p => ({partner_id: p.partner.id, range: p.range, sale_type: 'claim'})),
    ...combined.map(p => ({partner_id: p.partner.id, range: p.range, sale_type: 'combined'})),
    ...splitCombined.map(p => ({partner_id: p.partner.id, range: p.range, sale_type: 'combined', split: true})),
  ]
})

const validExport = computed(() => {
  return partnersParams.value.length > 0 && partnersParams.value.filter(p => !p.range).length === 0
})

const subscribeToSocket = (id: number) => {
  const sub = consumer.subscriptions.create({channel: "ReportChannel", report_id: id}, {
    connected: () => {
      console.log("Connected to report channel");
    },

    disconnected() {
      console.log("Disconnected from report channel");
    },

    received(data) {
      generatedReports.value.splice(0, 0, data)
      exporting.value = false
      consumer.subscriptions.remove(sub)
    }
  })
}

const monthIntervalString = (startDate, endDate) => {
  return {
    text: `${startDate.format('D')}-${endDate.format('D')} ${startDate.format('MMM')}`,
    value: `${startDate.format('MM/DD/YYYY')} - ${endDate.format('MM/DD/YYYY')}`
  }
}

const firstMonthHalf = (dayjsDate) => {
  const startDate = dayjsDate.startOf('month')
  const endDate = startDate.add(14, 'days')

  return monthIntervalString(startDate, endDate)
}

const secondMonthHalf = (dayjsDate) => {
  const startDate = dayjsDate.startOf('month').add(15, 'days').startOf('day')
  const endDate = startDate.endOf('month')

  return monthIntervalString(startDate, endDate)
}

const monthOptions = [0, 1, 2].map(el => {
  return [
    firstMonthHalf(dayjs().subtract(el, 'months')),
    secondMonthHalf(dayjs().subtract(el, 'months'))
  ]
}).reverse().flat()

const fullMonthOptions = [0, 1, 2, 3, 4, 5].map(el => {
  return {
    text: dayjs().subtract(el, 'months').startOf('month').format('MMM YYYY'),
    value: dayjs().subtract(el, 'months').startOf('month').format('MM/DD/YYYY') + ' - ' + dayjs().subtract(el, 'months').endOf('month').format('MM/DD/YYYY')
  }
}).reverse()

const isSelected = (opt) => {
  return opt.value === dateQuery.value
}

const selectMonthInterval = (opt) => {
  dateQuery.value = opt.value
}

const setAllBalance = (on) => {
  partnerWithDates.value.forEach(p => p.balance = on)
}

const setAllSplit = (on) => {
  partnerWithDates.value.forEach(p => p.split_combined = on)
}

const toggleAll = (prop) => {
  if (!['sale', 'claim', 'combined', 'split_combined'].includes(prop)) return;

  const isOn = partnerWithDates.value.filter(p => p[prop]).length > 0

  partnerWithDates.value.forEach(p => p[prop] = !isOn)
}

const balanceOn = computed(() => {
  return !partnerWithDates.value.find(p => p.balance === false)
})

const splitOn = computed(() => {
  return !partnerWithDates.value.find(p => p.split_combined === false)
})

const selectedLoadReport = (report: Report) => {
  loadReportsModal.value = false
  partnerWithDates.value = report.partnerData
}
</script>