<template>
  <div class="text-xs">
    <b-form-input :label="label" v-model="inputValue" class="grow"/>

    <div v-if="showVendorSuggestion" class="flex gap-2 pt-3 items-start truncate">
      <span>Use bill.com</span>
      <button class="btn-link" @click="applyVendorValue">{{vendorValue}}</button>
    </div>

    <div v-if="showPartnerSuggestion" class="flex gap-2 pt-3 text-left">
      <span>Use previous</span>
      <button class="btn-link" @click="applyPartnerValue">{{partnerValue}}</button>
    </div>

  </div>
</template>

<script setup lang="ts">
import BFormInput from "/js/components/forms/BFormInput.vue";
import {computed, defineProps} from "vue";

const props = defineProps({
  label: String,
  editMode: Boolean,
  modelValue: String,
  partnerValue: String,
  vendorValue: String
})

const emit = defineEmits(['update:modelValue'])

const inputValue = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})

const applyVendorValue = () => {
  inputValue.value = props.vendorValue
}

const applyPartnerValue = () => {
  inputValue.value = props.partnerValue
}

const showVendorSuggestion = computed(() => {
  return props.vendorValue && inputValue.value !== props.vendorValue
})

const showPartnerSuggestion = computed(() => {
  return props.partnerValue && inputValue.value !== props.partnerValue
})

</script>