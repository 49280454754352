<template>
  <div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="setIsOpen">
        <div class="fixed inset-0 z-50 overflow-y-auto bg-black/30">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0">
              <DialogOverlay class="fixed inset-0"/>
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>

            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95">

              <div class="inline-block w-full max-w-md my-8 text-left align-middle transition-all transform bg-white shadow-3xl rounded-2xl">
                <div class="flex flex-col items-center">
                  <DialogTitle as="div" class="text-xl font-medium leading-6 text-gray-900 flex p-3 justify-between w-full border-b border-gray-100">
                    <div><slot name="title"/></div>
                    <x-circle-icon class="w-6 h-6 text-gray-300 cursor-pointer hover:text-gray-500" @click="isOpen = false"/>
                  </DialogTitle>

                  <slot/>

                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup lang="ts">
import {Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {computed} from "vue";
import {XCircleIcon} from "@heroicons/vue/20/solid";

const props = defineProps({
  modelValue: Boolean
})

const emit = defineEmits(['update:modelValue'])

const isOpen = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})

function setIsOpen(value: boolean) {
  isOpen.value = value
}
</script>