<template>
  <div ref="scrollList" class="overflow-y-auto">

    <div class="p-3 flex flex-col gap-2 sticky top-0 bg-white">
      <b-form-input v-model="partnerQuery" placeholder="Search" label="App Partners"/>
      <div class="flex gap-2">
        <b-form-radio v-model="currentPartnerOption" :option="partnerAllOption"/>
        <b-form-radio v-model="currentPartnerOption" :option="partnerUnmatchedOption"/>
      </div>
    </div>
    <spinner v-if="isLoadingFirstPage" class="h-64"/>
    <ul class="divide-y divide-gray-200" v-else>
      <li v-for="partner in partnerStore.partners">
        <a href="#"
           class="block hover:bg-gray-50"
           :class="{'bg-gray-100': selectedOption === partner}"
           @click.prevent="selectedOption = partner">
          <div class="flex items-center px-4 py-4 sm:px-6">
            <div class="min-w-0 flex-1 flex items-center">
              <div class="min-w-0 flex-1 pr-4 gap-4">
                <div>
                  <p class="text-sm font-medium text-indigo-600 truncate"> {{ partner.name }} </p>
                  <p v-if="partner.analytics_business" class="mt-2 flex items-center text-sm text-gray-500 gap-2">
                  <span>
                    {{ partner.analytics_business.user.pref_username }}
                  </span>
                    <span v-if="partner.analytics_business.user.email">
                    {{ partner.analytics_business.user.email }}
                  </span>
                  </p>
                </div>
              </div>
            </div>

            <chevron-right-icon class="w-5 h-5 text-gray-400"/>
          </div>
        </a>
      </li>
    </ul>
    <spinner v-if="isPaginating"/>
  </div>
</template>

<script setup lang="ts">
import {PartnersAPI} from "/js/services/Partners";
import {appendItems, replaceItems, partnerStore} from "/js/stores/PartnerVendorStore";
import {computed, onMounted, ref, watch} from "vue";
import {ChevronRightIcon} from "@heroicons/vue/20/solid";
import getErrorMessage from "/js/composables/getErrorMessage";
import BFormRadio from "/js/components/forms/BFormRadio.vue";
import BFormInput from "/js/components/forms/BFormInput.vue";
import {BFormRadioOption} from "/js/components/forms/BFormRadioOption";
import {watchDebounced} from "@vueuse/core";
import {useToast} from "vue-toastification";
import {usePagination} from "/js/composables/usePagination";
import Spinner from "/js/components/Spinner.vue";
import {Partner} from "/js/models/Partner";

const toast = useToast()

const scrollList = ref(null)

const props = defineProps<{
  modelValue?: Partner
}>()

const partnerAllOption: BFormRadioOption = {text: "All", value: "all"}
const partnerUnmatchedOption: BFormRadioOption = {text: "Unmatched", value: "unmatched"}

const partnerQuery = ref("")
const currentPartnerOption = ref("unmatched")

const emit = defineEmits<{
  (e: 'update:modelValue', payload: Partner ): void
}>()

const selectedOption = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})

const fetchPaginatedPartners = async (page?: number) => {
  try {
    const paginationData = await PartnersAPI.getPartnersWithVendor(page, partnerQuery.value, currentPartnerOption.value)
    page ? appendItems(paginationData.data) : replaceItems(paginationData.data)
    return paginationData
  } catch (err) {
    toast.error(getErrorMessage(err))
    throw err
  }
}

const {
  isLoadingFirstPage,
  isPaginating,
  loadFirstPage
} = usePagination(scrollList, fetchPaginatedPartners)

onMounted(loadFirstPage)

watchDebounced(currentPartnerOption, loadFirstPage, {debounce: 300})
watchDebounced(partnerQuery, loadFirstPage, {debounce: 300})

</script>