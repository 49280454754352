import {Drop} from "/js/models/drop";
import {axiosGetJsonData, useAxios} from "/js/composables/useAxios";
import {Partner} from "/js/models/Partner";

export const DropsAPI = {
  getDrops: async (): Promise<[Drop]> => {
    return await axiosGetJsonData('/api/unattached_drops')
  },

  getPartners: async(dropId): Promise<[Partner]> => {
    return await axiosGetJsonData(`/api/drops/${dropId}/drop_matches`)
  }
}
