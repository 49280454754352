import {Partner, PartnerBasicAttributes, PartnerWithInvoices} from "/js/models/partner";
import {
  axiosGetJsonData as get,
  axiosPatchJsonData as patch,
  axiosPostJsonData as post,
  axiosGetPaginatedJsonData as getPaginated, PaginationData,
} from "/js/composables/useAxios";
import {makeQueryString} from "/js/composables/makeQueryString";

export const PartnersAPI = {
  getPartners: async (query?: String): Promise<[Partner]> => {
    return await get('/api/partners?' + makeQueryString({query: query}))
  },

  getPaginatedPartners: async (page?: Number, query?: String): Promise<PaginationData<[Partner]>> => {
    return await getPaginated('/api/partners?' + makeQueryString({query: query, page: page}))
  },

  getPartnersWithAvailableSales: async (range?: String, saleType?: String, billVendor?: Boolean): Promise<[Partner]> => {
    return await get('/api/partners/available_sales?' + makeQueryString({range: range, sale_type: saleType, bill_vendor: billVendor}))
  },

  createPartner: async (params): Promise<Partner> => {
    return await post('/api/partners', params)
  },

  createPartnerMatch: async (params): Promise<Partner> => {
    return await post('/api/partner_matches', params)
  },

  updatePartnerMatch: async (id, params): Promise<Partner> => {
    return await patch(`/api/partner_matches/${id}`, params)
  },

  getPartnersWithVendor: async (page?: Number, query?: String, matching?: String): Promise<PaginationData<[Partner]>> => {
    return await getPaginated('/api/partner_matches?' + makeQueryString({query: query, matching: matching, page: page}))
  },

  getPartnersWithInvoices: async (page?: Number, query?: String, processed?: String): Promise<PaginationData<[PartnerBasicAttributes]>> => {
    return await getPaginated('/api/invoice_matches?' + makeQueryString({query: query, processed: processed, page: page}))
  }
}
