<template>
  <div class="flex flex-col gap-6">

    <div class="bg-white rounded-lg shadow divide-y divide-gray-200 p-3 flex justify-between items-center">
      <div class="text-lg">Global Sync</div>
      <b-button variant="primary" @click="syncAll" :disabled="syncInProgress">Sync All</b-button>
    </div>

    <ul v-if="tasksStore" role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      <li v-for="type in tasksStore.task_types"
          class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">

        <background-task-card v-if="getTask(type)" :task="getTask(type)" v-model="syncModel[type]" :sync-in-progress="syncInProgress"/>
        <background-task-empty-card v-else/>
      </li>
    </ul>
  </div>

</template>
<script setup lang="ts">

import {BackgroundTasksAPI} from "/js/services/BackgroundTasks";
import getErrorMessage from "/js/composables/getErrorMessage";
import {useToast} from "vue-toastification";
import {computed, onMounted, ref} from "vue";
import {BackgroundTaskList} from "/js/models/BackgroundTask";
import BackgroundTaskCard from "/js/components/background_tasks/BackgroundTaskCard.vue";
import BackgroundTaskEmptyCard from "/js/components/background_tasks/BackgroundTaskEmptyCard.vue";
import consumer from "/channels/consumer";
import BButton from "/js/components/forms/BButton.vue";

const syncModel = ref({})

const toast = useToast()

const tasksStore = ref<BackgroundTaskList | null>(null)

const fetchTasks = async () => {
  try {
    tasksStore.value = await BackgroundTasksAPI.getTasks()
    let sync = {}
    tasksStore.value.tasks.forEach(t => sync[t.task_type] = t.status === 'running')
    syncModel.value = sync
  } catch (err) {
    toast.error(getErrorMessage(err))
  }
}

onMounted(() => {
  fetchTasks()
  subscribeToSocket()
})

const getTask = (type) => {
  if (!tasksStore.value) { return null }
  return tasksStore.value.tasks.find(t => t.task_type === type)
}

const subscribeToSocket = () => {
  consumer.subscriptions.create({channel: "BackgroundTasksChannel"}, {
    connected: () => {
      console.log("Connected to background tasks channel");
    },

    disconnected() {
      console.log("Disconnected from background tasks channel");
    },

    received() {
      fetchTasks()
    }
  })
}

const syncInProgress = computed(() => {
  return Object.keys(syncModel.value).find(k => syncModel.value[k] === true)
})

const syncAll = async () => {
  try {
    await BackgroundTasksAPI.createSync(null)
  } catch(err) {
    toast.error(getErrorMessage(err))
  }
}

</script>