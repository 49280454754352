import Highcharts from 'highcharts';

const options = function (title, xTitle, yTitle, tooltipHtmlFormat, plotOptionsFormat, data) {
  return {
    chart: {
      type: 'scatter',
      height: 800,
      plotBorderWidth: 1,
      zoomType: 'xy',
      panKey: 'shift',
      panning: {
        enabled: true,
        type: 'xy'
      }
    },

    legend: {
      enabled: true
    },

    title: {
      text: title
    },

    xAxis: {
      gridLineWidth: 1,
      title: {
        text: xTitle
      },
      labels: {
        format: '{value}'
      }
    },

    yAxis: {
      startOnTick: false,
      endOnTick: false,
      title: {
        text: yTitle
      },
      labels: {
        formatter: function () {
          return this.value < 0 || this.value > 100 ? '' : this.value + '%';
        }
      },
      maxPadding: 0.2
    },

    tooltip: {
      useHTML: true,
      headerFormat: '<table>',
      pointFormat: tooltipHtmlFormat,
      footerFormat: '</table>',
      followPointer: true
    },

    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          format: plotOptionsFormat
        }
      }
    },

    series: [{
      data: data,
      colorByPoint: true
    }]
  }
}

document.addEventListener('DOMContentLoaded', function () {
  const ids = ['total-container', 'claims-container', 'sales-container']

  // create a map of {id: data} for each drop tuning. id is one of the ids above
  const dropTunings = ids.map(id => {
    const container = document.getElementById(id);
    if (!container) return null;
    const chartType = container.dataset.chartType;
    const data = JSON.parse(container.dataset.dropTuning);
    return {id, data, chartType}
  }).filter(Boolean).reduce((acc, {id, data, chartType}) => {
    acc[id] = {data, chartType};
    return acc;
  }, {});


  // set a title based on the chart type and container id. chartType is one of drop, asset_type or asset_price
  // there should be a different title for each chart type and container id combination
  const title = (chartType, id) => {
    const totalByChartType = {
      drop: 'collection',
      asset_type: 'asset type',
      asset_price: 'asset price'
    }

    const totalByContainerId = {
      'total-container': 'sales and claims',
      'claims-container': 'claims',
      'sales-container': 'sales'
    }

    return `Total sell-through by ${totalByChartType[chartType]} (${totalByContainerId[id]})`;
  }

  const tooltipHtmlFormat = (chartType) => {
    switch (chartType) {
      case 'drop':
        return '<tr><th colspan="2"><h3>{point.drop}</h3></th></tr>' +
          '<tr><th>Minted:</th><td>{point.x} assets</td></tr>' +
          '<tr><th>Sold:</th><td>{point.sold} assets</td></tr>' +
          '<tr><th>Sell-Through:</th><td>{point.y}%</td></tr>' +
          '<tr><th>Partner:</th><td>{point.partner}</td></tr>';
      case 'asset_type':
        return '<tr><th colspan="2"><h3>{point.asset_type}</h3></th></tr>' +
          '<tr><th>Minted:</th><td>{point.x} assets</td></tr>' +
          '<tr><th>Sold:</th><td>{point.sold} assets</td></tr>' +
          '<tr><th>Sell-Through:</th><td>{point.y}%</td></tr>' +
          '<tr><th>Asset Type:</th><td>{point.asset_type}</td></tr>'
      case 'asset_price':
        return '<tr><th colspan="2"><h3>{point.asset_price}</h3></th></tr>' +
          '<tr><th>Minted:</th><td>{point.x} assets</td></tr>' +
          '<tr><th>Sold:</th><td>{point.sold} assets</td></tr>' +
          '<tr><th>Sell-Through:</th><td>{point.y}%</td></tr>' +
          '<tr><th>Asset Price:</th><td>{point.formatted_price}</td></tr>'
    }
  }

  const plotOptionsFormat = (chartType) => {
    switch (chartType) {
      case 'drop':
        return '{point.drop}';
      case 'asset_type':
        return '{point.asset_type}';
      case 'asset_price':
        return '{point.formatted_price}';
    }
  }

  if (dropTunings['total-container']) {
    const chartType = dropTunings['total-container'].chartType
    Highcharts.chart('total-container', options(
        title(dropTunings['total-container'].chartType, 'total-container'),
        'Total Minted',
        'Total Sold %',
        tooltipHtmlFormat(chartType),
        plotOptionsFormat(chartType),
        dropTunings['total-container'].data
      )
    )
  }

  if (dropTunings['claims-container']) {
    const chartType = dropTunings['claims-container'].chartType
    Highcharts.chart('claims-container', options(
        title(dropTunings['claims-container'].chartType, 'claims-container'),
        'Total Minted (claims)',
        'Total Claimed %',
        tooltipHtmlFormat(chartType),
        plotOptionsFormat(chartType),
        dropTunings['claims-container'].data
      )
    )
  }

  if (dropTunings['sales-container']) {
    const chartType = dropTunings['sales-container'].chartType
    Highcharts.chart('sales-container', options(
        title(dropTunings['sales-container'].chartType, 'sales-container'),
        'Total Minted (sales)',
        'Total Sold %',
        tooltipHtmlFormat(chartType),
        plotOptionsFormat(chartType),
        dropTunings['sales-container'].data
      )
    )
  }


})