import $ from 'jquery/dist/jquery'
import moment from "moment/src/moment";
import {Litepicker} from "litepicker";
import 'litepicker/dist/plugins/ranges'

$(function () {
  $('[data-toggle="daterangepicker"]').each((idx, el) => {
    new Litepicker({
      element: el,
      plugins: ['ranges'],
      format: "MM/DD/YYYY",
      singleMode: false,
      numberOfColumns: 2,
      numberOfMonths: 2,
      ranges: {
        customRanges: {
          'This Month': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
          'Last Month': [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
          '2 Months Ago': [moment().subtract(2, 'month').startOf('month').toDate(), moment().subtract(2, 'month').endOf('month').toDate()],
          '3 Months Ago': [moment().subtract(3, 'month').startOf('month').toDate(), moment().subtract(3, 'month').endOf('month').toDate()],
          'Quarter 1': [moment().month(0).startOf('month').toDate(), moment().month(2).endOf('month').toDate()],
          'Quarter 2': [moment().month(3).startOf('month').toDate(), moment().month(5).endOf('month').toDate()],
          'Quarter 3': [moment().month(6).startOf('month').toDate(), moment().month(8).endOf('month').toDate()],
          'Quarter 4': [moment().month(9).startOf('month').toDate(), moment().month(11).endOf('month').toDate()],
        }
      }
    });
  })

  $('[data-toggle="datepicker"]').each((idx, el) => {
    new Litepicker({
      element: el,
      format: "MM/DD/YYYY",
    });
  })

})
