<template>
  <div class="flex items-center justify-between p-4">
    <h4 class="font-medium">{{ title }}</h4>
    <button class="btn-primary" @click="selectAllItems">Select All</button>
  </div>
  <ul class="divide-y divide-gray-200 overflow-hidden">
    <li v-for="item in dropItems">
      <a class="block hover:bg-gray-50 cursor-pointer" @click="toggleItem(item)">
        <div class="p-4 flex flex-col gap-2">
          <div class="flex items-center gap-2">
            <input type="checkbox" :value="item.id" v-model="model">
            <p class="text-sm font-medium text-indigo-600 truncate"> {{ item.name }} </p>
          </div>

          <div class="text-xs text-gray-400">{{ item.description }}</div>

          <div class="flex flex-wrap gap-2">
            <div v-for="contract in item.contracts" class="badge">
              {{ contract.name }}
            </div>
          </div>

        </div>

      </a>
    </li>
  </ul>
</template>

<script setup lang="ts">
import {Item} from "/js/models/Item";
import {computed, PropType, ref} from "vue";

const emit = defineEmits(['update:modelValue', 'change'])

const props = defineProps({
  title: {
    type: String
  },
  dropItems: {
    type: Array as PropType<Item[]>
  },
  modelValue: {
    type: Array as PropType<number[]>
  },
})

const model = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})

function selectAllItems() {
  updateValue(props.dropItems.map(el => el.id))
}

function toggleItem(item: Item) {
  const data = props.modelValue

  if (!props.modelValue.includes(item.id)) {
    data.push(item.id)
    // selectedItems.value.push(item.id)
  } else {
    const idx = props.modelValue.indexOf(item.id)
    if (idx > -1) {
      data.splice(idx, 1)
      // selectedItems.value.splice(idx, 1)
    }
  }

  updateValue(data)
}

const updateValue = (value) => {
  emit('update:modelValue', value)
  emit('change', value)
}

</script>