<template>
  <div class="flex flex-col gap-1">
    <label v-if="label" :for="id" class="block text-sm font-medium text-gray-700">
      {{ label }}
    </label>
    <input type="text"
           class="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
           :class="{'border-red-500': (hasErrors || errorHighlight)}"
           :value="modelValue"
           @input="updateValue"
           ref="datePicker"/>
    <div v-if="hasErrors" class="text-red-500 text-sm">{{ errorsString }}</div>
  </div>
</template>

<script setup lang="ts">
import {Litepicker} from "litepicker";
import {computed, onMounted, ref, watch} from "vue";
import {dynamicId} from "/js/composables/DynamicId";

const props = defineProps({
  name: {
    type: String
  },
  label: {
    type: String,
    required: false,
    default: null
  },
  modelValue: String,
  pickerType: {
    type: String
  },
  errors: {
    type: Array,
    required: false,
    default: []
  },
  errorHighlight: {
    type: Boolean,
    required: false,
    default: false
  }
})
const emit = defineEmits(['update:modelValue', 'change'])

const id = ref(dynamicId())
const datePicker = ref(null);
const errorsString = computed(() => {
  return props.errors.join(", ")
})

const hasErrors = computed(() => {
  return props.errors && props.errors.length > 0
})

let litePicker = null

onMounted(() => {
  switch (props.pickerType) {
    case "daterangepicker": {
      litePicker = new Litepicker({
        element: datePicker.value,
        format: "MM/DD/YYYY",
        plugins: ['ranges'],
        singleMode: false,
        numberOfColumns: 2,
        numberOfMonths: 2,
        setup: (picker) => {
          // @ts-ignore
          picker.on('selected', (date1, date2) => {
            console.log('triggering change');
            emit('update:modelValue', datePicker.value.value)
            emit('change', datePicker.value.value)
          });
        },
      });
      break
    }
    case "datepicker": {
      litePicker = new Litepicker({
        element: datePicker.value,
        format: "YYYY-MM-DD",
        setup: (picker) => {
          // @ts-ignore
          picker.on('selected', (date1, date2) => {
            emit('update:modelValue', datePicker.value.value)
            emit('change', datePicker.value.value)
          });
        },
      });
      break
    }
  }
})

watch(() => props.modelValue, (val) => {
  if (litePicker) {
    if (val && val.length) {
      switch (props.pickerType) {
        case "daterangepicker": {
          const [first, last] = val.split(" - ")
          litePicker.setDateRange(first, last)
          break
        }
        case "datepicker": {
          litePicker.setDate(val)
          break
        }
      }
    } else {
      litePicker.setDate(null)
      litePicker.setDateRange(null, null)
    }
  }
})


// this only triggers when the input is changed, not when the datepicker is changed
const updateValue = (event) => {
  emit('update:modelValue', event.target.value)
}
</script>
