<template>
  <div>
    <label v-if="label"
           :for="id"
           class="block text-sm font-medium text-gray-700">
      {{ label }}
    </label>

    <input type="text"
           :name="name"
           :id="id"
           :class="{'border-red-500': hasErrors}"
           :value="modelValue"
           :placeholder="placeholder"
           ref="input"
           @input="updateValue"
    />
    <div v-if="hasErrors" class="text-red-500 text-sm">{{ errorsString }}</div>
  </div>
</template>

<script setup lang="ts">
import {computed, ref} from "vue";
import {dynamicId} from "/js/composables/DynamicId";

const props = defineProps({
  name: {
    type: String
  },
  label: {
    type: String,
    required: false,
    default: null
  },
  placeholder: {
    type: String,
    required: false,
    default: null
  },
  modelValue: {
    type: [String, Number]
  },
  errors: {
    type: Array,
    required: false,
    default: []
  }
})

const id = ref(dynamicId())

const emit = defineEmits(['update:modelValue'])

const input = ref(null)

const errorsString = computed(() => {
  return props.errors.join(", ")
})

const hasErrors = computed(() => {
  return props.errors && props.errors.length > 0
})

const updateValue = (event) => {
  emit('update:modelValue', event.target.value)
}

const focus = function () {
  input.value.focus()
}

</script>