<template>
  <div class="w-full flex flex-col my-3">
    <div v-for="report in reports">
      <button class="grid grid-cols-4 gap-3 p-3 w-full hover:bg-gray-50 text-left"
              @click="selectReport(report)">
        <span>{{ report.id }}</span>
        <span class="col-span-3 flex flex-col">
        <span v-for="data in report.partnerData">
          {{ data.partner.name }}
        </span>
      </span>
      </button>
      <hr>
    </div>


  </div>
</template>

<script setup lang="ts">
import {computed, onMounted, ref} from "vue";
import {
  partnersWithDateFromParams,
  PartnerWithDate,
  ReportsAPI,
  Report,
  SummaryParamsArchiveReportContainer
} from "/js/services/Reports";

const emit = defineEmits(['select-report']);

const reportsContainer = ref<SummaryParamsArchiveReportContainer | null>(null);

const reports = computed(() => {
  const container = reportsContainer.value;
  if (!container) return []

  return container.reports.map(report => {
    return {
      id: report.id,
      partnerData: partnersWithDateFromParams(container, report.id)
    }
  })
})

onMounted(async () => {
  reportsContainer.value = await ReportsAPI.getSalesExports()
})

const selectReport = (report: Report) => {
  emit('select-report', report)
}

</script>

<script lang="ts">
export default {
  name: "ReportLoader"
}
</script>