import {reactive} from 'vue'
import {Drop} from "/js/models/drop";
import {Item} from "/js/models/item";
const dropStore = reactive({ drops: [] as Drop[]})

const replaceItems = function(items: Item[]) {
  dropStore.drops.forEach((drop) => {
    items.forEach((item) => {
      let idx = drop.items.findIndex(el => el.id === item.id)
      if (idx > -1) {
        drop.items[idx] = item
      }
    })
  })
}

const replaceDrops = function(newDrops: Drop[]) {
  dropStore.drops = newDrops
}

export {dropStore, replaceItems, replaceDrops}

