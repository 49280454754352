<template>
  <div class="grid grid-cols-8 gap-3 flex h-[calc(100vh-110px)]">

    <unmatched-partner-list v-model="activeData.partner" class="col-span-2 rounded-md bg-white shadow"/>

    <div class="col-span-3 rounded-md bg-white shadow overscroll-contain overflow-y-auto">

      <div class="p-3 sticky top-0 bg-white">
        <b-form-input v-model="billQuery" placeholder="Search" label="Bill.com Vendors"/>
      </div>

      <div>
        <bill-vendor-list v-model:query="billQuery" v-model="activeData.vendor"/>
      </div>

    </div>

    <div class="col-span-3 rounded-md bg-white shadow overscroll-contain overflow-y-auto">
      <partner-form v-model:bill-vendor="activeData.vendor" v-model:partner="activeData.partner"/>
    </div>

  </div>
</template>

<script setup lang="ts">

interface ActiveData {
  partner?: Partner
  vendor?: BillVendor
}

import {reactive, ref, watch} from "vue";
import BillVendorList from "/js/components/partner_matches/BillVendorList.vue";
import PartnerForm from "/js/components/partner_matches/PartnerForm.vue";
import BFormInput from "/js/components/forms/BFormInput.vue";
import {BillVendor} from "/js/models/BillVendor";
import UnmatchedPartnerList from "/js/components/partner_matches/UnmatchedPartnerList.vue";
import {Partner} from "/js/models/Partner";

const activeData = reactive({
  partner: undefined,
  vendor: undefined
} as ActiveData)

const billQuery = ref("")

watch(() => activeData.partner, (val) => {
  if (val) {
    billQuery.value = val.name
  }
  activeData.vendor = undefined
})

</script>