import {reactive} from 'vue'
import {Partner} from "/js/models/partner";

export const partnerStore = reactive({partners: [] as Partner[]})

export const replaceItems = (newItems: Partner[]) => {
  partnerStore.partners = newItems
}

export const appendItems = (newItems: Partner[]) => {
  partnerStore.partners = partnerStore.partners.concat(newItems)
}

export const replacePartner = (partner: Partner) => {
  const idx = partnerStore.partners.findIndex(p => p.id === partner.id)
  if (idx > 0) {
    partnerStore.partners[idx] = partner
  }
}
