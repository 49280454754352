<template>
  <div class="flex items-center justify-between p-4">
    <h4 class="font-medium">Select Contracts</h4>
    <div class="flex items-center gap-2">
      <button class="btn-primary" @click="handleCreateContracts" v-if="modelValue.length">Add to {{ modelValue.length }}
        Contract{{ selectedContractIds.length === 1 ? '' : 's' }}
      </button>
    </div>
  </div>

  <div class="p-3">
    <b-form-input v-model="partnerSearchQuery" placeholder="Search" label="App Partners"/>
  </div>

  <div class="p-4">
    <h4 class="font-medium">Analytics Connected Partners</h4>
  </div>
  <ul class="divide-y divide-gray-200 overflow-hidden">
    <li v-for="partner in dropPartnerList">

      <div class="flex flex-col gap-2">
        <p class="pt-4 px-4 text-sm font-medium text-gray-600 truncate"> {{ partner.name }} </p>

        <a v-for="contract in partner.contracts"
           class="block hover:bg-gray-50 cursor-pointer rounded p-4"
           @click="toggleContract(contract)">
          <div class="flex items-center gap-2">
            <input type="checkbox" :value="contract.id" v-model="selectedContractIds">
            <p class="text-sm font-medium text-indigo-600 truncate"> {{ contract.name }} </p>
          </div>
        </a>

      </div>
    </li>
  </ul>


  <div class="p-4">
    <h4 v-if="partnerList.length" class="font-medium">Search Results Partners</h4>
  </div>

  <ul v-if="partnerList.length" class="divide-y divide-gray-200 overflow-hidden">
    <li v-for="partner in partnerList">

      <div class="flex flex-col gap-2">
        <p class="pt-4 px-4 text-sm font-medium text-gray-600 truncate"> {{ partner.name }} </p>

        <a v-for="contract in partner.contracts"
           class="block hover:bg-gray-50 cursor-pointer rounded p-4"
           @click="toggleContract(contract)">
          <div class="flex items-center gap-2">
            <input type="checkbox" :value="contract.id" v-model="selectedContractIds">
            <p class="text-sm font-medium text-indigo-600 truncate"> {{ contract.name }} </p>
          </div>
        </a>

      </div>
    </li>
  </ul>
</template>

<script setup lang="ts">
import {computed, onMounted, PropType, reactive, ref, watch} from "vue";
import {Contract} from "/js/models/Contract";
import getErrorMessage from "/js/composables/getErrorMessage";
import BFormInput from "/js/components/forms/BFormInput.vue";
import {PartnersAPI} from "/js/services/Partners";
import {Partner} from "/js/models/Partner";
import {watchDebounced} from "@vueuse/core";
import {associateItems} from "/js/services/BulkContractAssociations";
import {replaceItems} from "/js/stores/DropStore";
import {Drop} from "/js/models/Drop";
import {DropsAPI} from "/js/services/Drops";
import {useToast} from "vue-toastification";
const toast = useToast()

const emit = defineEmits(['update:modelValue'])

const partnerSearchQuery = ref("")
const loading = ref(false)

let partnerList = ref([] as Partner[])
let dropPartnerList = ref([] as Partner[])

const props = defineProps({
  modelValue: {
    type: Array as PropType<number[]>,
    default: []
  },
  itemIds: {
    type: Array as PropType<number[]>,
    required: true
  },
  drop: {
    type: Object as PropType<Drop>,
    required: true
  }
})

const selectedContractIds = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})

watchDebounced(partnerSearchQuery, () => {
  fetchPartners()
}, {debounce: 500})

onMounted(() => {
  fetchDropPartner()
})

watch(() => props.drop, () => {
  fetchDropPartner()
})

const fetchDropPartner = async () => {
  loading.value = true;

  try {
    dropPartnerList.value = await DropsAPI.getPartners(props.drop.id)
  } catch (err) {
    toast.error(getErrorMessage(err))
  }

  loading.value = false
}

const fetchPartners = async () => {
  loading.value = true;

  try {
    partnerList.value = await PartnersAPI.getPartners(partnerSearchQuery.value)
  } catch (err) {
    toast.error(getErrorMessage(err))
  }

  loading.value = false
}

function toggleContract(contract: Contract) {
  const data = selectedContractIds.value

  if (!props.modelValue.includes(contract.id)) {
    data.push(contract.id)
    // selectedItems.value.push(item.id)
  } else {
    const idx = props.modelValue.indexOf(contract.id)
    if (idx > -1) {
      data.splice(idx, 1)
      // selectedItems.value.splice(idx, 1)
    }
  }

  selectedContractIds.value = data
}

const handleCreateContracts = async () => {
  try {
    const items = await associateItems(props.modelValue, props.itemIds)
    const contractCount = props.modelValue.length
    replaceItems(items)
    selectedContractIds.value = []
    toast.success(`${items.length} editions were attached to ${contractCount} contracts.`)
  } catch(err) {
    toast.error(getErrorMessage(err))
  }
}

</script>