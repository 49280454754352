function getErrorMessage(error) {
  const valid = error && error.response && error.response.data && error.response.data.errors
  if (!valid) {
    console.log('invalid errors', error);
  }

  const errors = error.response.data.errors
  if (errors) {
    if (typeof errors === "string") {
      return errors
    } else if (errors.base) {
      return errors.base
    } else {
      return Object.keys(errors).map(k => `${k} ${errors[k]}`).join(", ")
    }
  }

  return error
}

export default getErrorMessage