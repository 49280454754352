<template>
  <div class="grid grid-cols-8 gap-3 flex h-[calc(100vh-110px)]">

    <div class="col-span-2 rounded-md bg-white shadow overscroll-contain overflow-y-auto" ref="partnerListRef">
      <div class="p-3 flex flex-col gap-2 sticky top-0 bg-white">
        <b-form-input v-model="partnerQuery" placeholder="Search" label="App Partners"/>
        <div class="flex gap-2">
          <b-form-radio v-model="currentPartnerOption" :option="partnerAllOption"/>
          <b-form-radio v-model="currentPartnerOption" :option="partnerUnmatchedOption"/>
        </div>
      </div>

      <spinner v-if="isLoadingFirstPage" class="p-3"/>
      <ul v-else class="divide-y divide-gray-200">
        <li v-for="partner in partners">
          <a href="#"
             class="p-4 block hover:bg-gray-50"
             :class="{'bg-gray-100': activePartner === partner}"
             @click.prevent="activePartner = partner">
            {{ partner.name }}
          </a>
        </li>
      </ul>
    </div>

    <div class="col-span-3 rounded-md bg-white overscroll-contain overflow-y-auto flex flex-col gap-4 px-2">
      <div class="flex justify-between py-2">
        <div class="text-lg font-medium">
          <div v-if="activePartner">
            <span>{{ activePartner.name }}</span>
          </div>
          <div v-else class="italic">Select a partner</div>
          <div v-if="activePartner" class="text-xs font-normal italic">
            Viewing {{ showUnprocessedOnly ? 'Unprocessed' : 'All' }} - {{ partnerInvoices.length }} total,
            {{ unprocessedInvoices.length }} unprocessed
            <div>
              <div class="text-medium">Totals:</div>
              <div v-for="(total, type) in invoiceLineSplitTotals">
                {{ type }}: ${{ Math.round(total * 100) / 100 }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="activePartner">
          <button class="btn-primary" @click="showUnprocessedOnly = !showUnprocessedOnly">
            <span v-if="showUnprocessedOnly">View All</span>
            <span v-else>View Unprocessed</span>
          </button>
        </div>
      </div>

      <div ref="parent" class="flex flex-col gap-4">
        <div v-for="invoice in currentInvoices"
             v-if="activePartner"
             :key="invoice.id"
             :class="[invoice.partner_payment === true ? 'ring-2 ring-green-400' : '', invoice.partner_payment === false ? 'ring-2 ring-red-400' : '' ]"
             class="relative bg-white border rounded-lg shadow-sm focus:outline-none border-gray-300">
          <div class="p-4 flex flex-col w-full">
            <span class="block text-sm font-medium text-gray-900">
              <span v-if="invoice.description">{{ invoice.description }}</span>
              <span v-else class="italic text-gray-500">Invoice Description Missing</span>
            </span>

            <span class="mt-1 flex items-center text-sm text-gray-500">
              ${{ invoice.amount }}
            </span>

            <span class="mt-6 text-sm font-medium text-gray-900 flex justify-between">
              <span>Invoiced on {{ invoice.date }}</span>
              <span v-if="invoice.process_date">Paid on {{ invoice.process_date }}</span>
              <span v-else class="text-red-700">Not Paid</span>
            </span>

            <hr>

            <div class="divide-y divide-gray-200">
              <div v-for="line in invoice.invoice_lines" class="text-sm py-4 flex gap-3 items-center">
                <div>${{ line.amount }}</div>
                <span class="text-gray-500 grow">[{{line.id}}] {{ line.description }}</span>

                <div class="flex flex-col gap-1">
                  <b-form-select @change="handleLineTypeChanged(line)"
                                 v-model="line.line_type"
                                 :options="lineTypeOptions"
                                 class="w-48"/>
                  <date-range-picker
                      v-model="line.range"
                      class="w-48"
                      @change="handleLineRangeChanged(line)"
                      picker-type="daterangepicker"
                  />

                  <button class="btn-link self-end" v-if="line.range">
                    Clear Date
                  </button>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script setup lang="ts">
import {PartnersAPI} from "/js/services/Partners";
import getErrorMessage from "/js/composables/getErrorMessage";
import {computed, onMounted, ref, watch} from "vue";
import {PartnerBasicAttributes} from "/js/models/Partner";
import {CheckCircleIcon, XCircleIcon} from "@heroicons/vue/20/solid";
import {BillInvoice, BillInvoiceLine} from "/js/models/BillInvoice";
import {BillAPI} from "/js/services/BillVendors";
import {useToast} from "vue-toastification";
import BFormInput from "/js/components/forms/BFormInput.vue";
import BFormRadio from "/js/components/forms/BFormRadio.vue";
import {BFormRadioOption} from "/js/components/forms/BFormRadioOption";
import {watchDebounced} from "@vueuse/core";
import {useAutoAnimate} from "@formkit/auto-animate/vue";
import {usePagination} from "/js/composables/usePagination";
import Spinner from "/js/components/Spinner.vue";
import BFormCheckbox from "/js/components/forms/BFormCheckbox.vue";
import BFormSelect from "/js/components/forms/BFormSelect.vue";
import DateRangePicker from "/js/components/forms/DateRangePicker.vue";

const [parent] = useAutoAnimate()

const toast = useToast()

const partners = ref([] as PartnerBasicAttributes[])
const activePartner = ref(null as PartnerBasicAttributes | null)
const partnerInvoices = ref([] as BillInvoice[])
const showUnprocessedOnly = ref(false)

const partnerAllOption: BFormRadioOption = {text: "All", value: "all"}
const partnerUnmatchedOption: BFormRadioOption = {text: "Unprocessed", value: "unprocessed"}

const partnerQuery = ref("")
const currentPartnerOption = ref("unprocessed")

const partnerListRef = ref(null)

const fetchPaginatedPartners = async (page?: number) => {
  try {
    const paginationData = await PartnersAPI.getPartnersWithInvoices(page, partnerQuery.value, currentPartnerOption.value)
    partners.value = page ? partners.value.concat(paginationData.data) : paginationData.data
    return paginationData
  } catch (err) {
    toast.error(getErrorMessage(err))
    throw err
  }
}

const {
  isLoadingFirstPage,
  isPaginating,
  loadFirstPage
} = usePagination(partnerListRef, fetchPaginatedPartners)


const lineTypeOptions = [
  {text: "Sale", value: "sale"},
  {text: "Claim", value: "claim"},
  {text: "Other", value: "other"},
]

const invoiceLineSplitTotals = computed(() => {
  const totals = {
    sale: 0,
    claim: 0,
    other: 0,
  }

  for (const invoice of partnerInvoices.value) {
    for (const line of invoice.invoice_lines) {
      totals[line.line_type] += line.amount
    }
  }

  return totals
})

const fetchInvoices = async () => {
  try {
    partnerInvoices.value = await BillAPI.getInvoices(activePartner.value.id)
  } catch (err) {
    toast.error(getErrorMessage(err))
  }
}

// const makePartnerPayment = async (invoice: BillInvoice, partnerPayment: Boolean) => {
//   try {
//     const invoiceResponse = await BillAPI.markPartnerPayment(invoice.id, partnerPayment)
//
//     const idx = partnerInvoices.value.findIndex(inv => inv.id === invoiceResponse.id)
//
//     if (idx >= 0) {
//       partnerInvoices.value[idx] = invoiceResponse
//     }
//   } catch (err) {
//     toast.error(getErrorMessage(err))
//   }
// }

const handleLineTypeChanged = async (line: BillInvoiceLine) => {
  const invoice: BillInvoice = await BillAPI.updateInvoiceLine(line.id, line.line_type)
  const idx = partnerInvoices.value.findIndex(inv => inv.id === invoice.id)
  partnerInvoices.value[idx] = invoice
}

const handleLineRangeChanged = async (line: BillInvoiceLine) => {
  // todo: change to a save button
  console.log('updating');

  if (!line.range || line.range.split(" - ").length !== 2) {
    return
  }

  // range has a format like 01/02/2023 - 01/03/2023 (mm/dd/yyyy)
  let from = line.range.split(" - ")[0]
  let to = line.range.split(" - ")[1]

  // convert it to a format like 2023-02-01 (yyyy-mm-dd)
  const fromParts = from.split("/")
  from = `${fromParts[2]}-${fromParts[0]}-${fromParts[1]}`

  const toParts = to.split("/")
  to = `${toParts[2]}-${toParts[0]}-${toParts[1]}`

  console.log(`updating ${line.id} with ${from} - ${to}`);
  const invoice = await BillAPI.updateInvoiceLineRange(line.id, from, to)

  // const idx = partnerInvoices.value.findIndex(inv => inv.id === invoice.id)
  // partnerInvoices.value[idx] = invoice
}

const unprocessedInvoices = computed(() => partnerInvoices.value.filter(el => el.invoice_lines.find(l => !l.line_type)))
const currentInvoices = computed(() => showUnprocessedOnly.value ? unprocessedInvoices.value : partnerInvoices.value)

onMounted(loadFirstPage)

watch(activePartner, (value) => {
  partnerInvoices.value = []
  if (value) {
    fetchInvoices()
  }
})

watchDebounced(currentPartnerOption, loadFirstPage, {debounce: 300})
watchDebounced(partnerQuery, loadFirstPage, {debounce: 300})

</script>