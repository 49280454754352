<template>
  <div v-if="loading" class="p-3">Loading..</div>
  <ul v-else class="divide-y divide-gray-200">
    <li v-for="item in billVendorStore.items">
      <a href="#"
         class="block hover:bg-gray-50"
         :class="{'bg-gray-100': selectedOption === item}"
         @click.prevent="selectedOption = item">
        <div class="flex items-center px-4 py-4 sm:px-6">
          <div class="min-w-0 flex-1 flex items-center">
            <div class="min-w-0 flex-1 pr-4 gap-4">
              <div>
                <div class="text-sm font-medium text-indigo-600 truncate"> {{ item.name }}</div>
                <div class="mt-2 flex flex-col text-sm text-gray-500 gap-2">
                  <div><b>Vendor ID:</b> <span>{{ item.vendor_id }}</span></div>
                  <div><b>Email:</b> <span>{{ item.email }}</span></div>
                  <div><b>Address:</b> <span>{{ item.address }}</span></div>
                  <div><b>Contact Name:</b> <span>{{ item.contact_name }}</span></div>
                  <div><b>Phone:</b> <span>{{ item.phone }}</span></div>
                </div>
              </div>
            </div>
          </div>

          <chevron-right-icon class="w-5 h-5 text-gray-400"/>
        </div>
      </a>
    </li>
  </ul>
</template>

<script setup lang="ts">
import {BillAPI} from "/js/services/BillVendors";
import {replaceItems} from "/js/stores/BillVendorStore";
import {computed, onMounted, ref, watch} from "vue";
import {ChevronRightIcon} from "@heroicons/vue/20/solid";
import {billVendorStore} from "/js/stores/BillVendorStore";
import {watchDebounced} from "@vueuse/core";
import getErrorMessage from "/js/composables/getErrorMessage";
import {useToast} from "vue-toastification";
import {BillVendor} from "/js/models/BillVendor";
const toast = useToast()

const props = defineProps<{
  modelValue?: BillVendor,
  query: string
}>()

const loading = ref(false)
const error = ref(null);

const emit = defineEmits(['update:modelValue', 'update:query'])

const selectedOption = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})

const currentQuery = computed({
  get: () => props.query,
  set: (value) => emit('update:query', value)
})

watchDebounced(currentQuery, () => {
  fetchBillVendors()
}, {debounce: 500})

const fetchBillVendors = async () => {
  selectedOption.value = undefined
  loading.value = true

  try {
    let results = await BillAPI.getBillVendors(currentQuery.value)
    replaceItems(results)
  } catch (err) {
    toast.error(getErrorMessage(err))
  }

  loading.value = false
}

onMounted(fetchBillVendors)

</script>