<template>
  <div class="flex flex-col gap-4">
    <div class="flex justify-between sticky top-0 bg-white p-3">
      <h5 class="text-lg font-medium">
        <span v-if="editMode">Edit Partner - {{ partner.name }}</span>
        <span v-else>Create Partner</span>
      </h5>

      <button class="btn-primary" v-if="editMode" @click="unselectPartner">Create New</button>
    </div>

    <div class="flex flex-col gap-4 p-3">
      <revert-input label="Name"
                    v-model="name"
                    :partner-value="partner ? partner.name : null"
                    :vendor-value="billVendor ? billVendor.name : null"/>

      <revert-input label="Address"
                    v-model="address"
                    :partner-value="partner ? partner.address : null"
                    :vendor-value="billVendor ? billVendor.address : null"/>

      <revert-input label="Contact Name"
                    v-model="contactName"
                    :partner-value="partner ? partner.contact_name : null"
                    :vendor-value="billVendor ? billVendor.contact_name : null"/>

      <revert-input label="Email"
                    v-model="email"
                    :partner-value="partner ? partner.contact_email : null"
                    :vendor-value="billVendor ? billVendor.email : null"/>

      <revert-input label="Phone"
                    v-model="phone"
                    :partner-value="partner ? partner.contact_phone : null"
                    :vendor-value="billVendor ? billVendor.phone : null"/>

      <div v-if="!editMode" class="grid grid-cols-6 gap-6">
        <div class="col-span-6 sm:col-span-3">
          <b-form-input name="contract[name]"
                        label="Contract Name"
                        v-model="contractFormData.name"
                        :errors="errors.name"/>
        </div>

        <div class="col-span-6 sm:col-span-3">
          <date-range-picker v-model="contractFormData.date"
                             name="contract[date]"
                             label="Date"
                             picker-type="datepicker"
                             :errors="errors.date"/>
        </div>

        <div class="col-span-6 sm:col-span-3">
          <date-range-picker v-model="contractFormData.effective_date"
                             name="contract[effective_date]"
                             label="Effective Date"
                             picker-type="datepicker"
                             :errors="errors.effective_date"/>
        </div>

        <div class="col-span-6 sm:col-span-3">
          <date-range-picker v-model="contractFormData.expiration_date"
                             name="contract[expiration_date]"
                             label="Expiration Date"
                             picker-type="datepicker"
                             :errors="errors.expiration_date"/>
        </div>

        <div class="col-span-6 sm:col-span-3">
          <b-form-input name="contract[primary_sale_rev_share]"
                        label="Primary Sale Rev Share %"
                        v-model="contractFormData.primary_sale_rev_share"
                        :errors="errors.primary_sale_rev_share"/>
        </div>

        <div class="col-span-6 sm:col-span-3">
          <b-form-input name="contract[secondary_sale_rev_share]"
                        label="Secondary Sale Rev Share %"
                        v-model="contractFormData.secondary_sale_rev_share"
                        :errors="errors.secondary_sale_rev_share"/>
        </div>
      </div>

      <button class="btn-primary"
              :disabled="isInvalidForm"
              @click="handleCreateClick">
        <template v-if="loading">Please Wait...</template>
        <template v-else>
          <template v-if="editMode">Update Partner {{ partner.name }}</template>
          <template v-else>Create Partner {{ name }}</template>
        </template>
      </button>
    </div>

  </div>
</template>

<script setup lang="ts">

import {computed, defineProps, ref, PropType, watch, reactive, Prop} from "vue";
import {BillVendor} from "/js/models/BillVendor";
import BFormInput from "/js/components/forms/BFormInput.vue"
import DateRangePicker from "/js/components/forms/DateRangePicker.vue"
import {PartnersAPI} from "/js/services/Partners";
import getErrorMessage from "/js/composables/getErrorMessage";
import {Partner} from "/js/models/Partner";
import RevertInput from "/js/components/partner_matches/RevertInput.vue";
import {replacePartner} from "/js/stores/PartnerVendorStore";
import {useToast} from "vue-toastification";
const toast = useToast()

const props = defineProps({
  billVendor: {
    type: Object as PropType<BillVendor>,
    required: false
  },
  partner: {
    type: Object as PropType<Partner>,
    required: false
  }
})

const emit = defineEmits(['update:partner'])

const partner = computed({
  get: () => props.partner,
  set: (value) => emit('update:partner', value)
})

const loading = ref(false)

const name = ref("")
const address = ref("")
const contactName = ref("")
const email = ref("")
const phone = ref("")

const contractFormData = reactive({
  name: "",
  date: null,
  effective_date: null,
  expiration_date: null,
  primary_sale_rev_share: 100,
  secondary_sale_rev_share: 100
})

const errors = ref({})

const params = computed(() => {
  let billVendorId = null

  if (props.billVendor) {
    billVendorId = props.billVendor.vendor_id
  }

  const partnerAttrs = {
    name: name.value,
    address: address.value,
    contact_name: contactName.value,
    contact_email: email.value,
    contact_phone: phone.value,
    ...(billVendorId && {bill_vendor_id: billVendorId})
  }

  return {
    partner: {
      ...partnerAttrs,
      ...(!editMode.value && {contracts_attributes: [{...contractFormData}]})
    }
  }
})

const isInvalidForm = computed(() => {
  const invalidPartner = [name.value, address.value, contactName.value, email.value, phone.value].filter(v => v === "" || v === null).length > 0
  const invalidContract = Object.keys(contractFormData).filter(k => {
    return contractFormData[k] === "" || contractFormData[k] === null
  }).length > 0

  if (editMode.value) {
    return invalidPartner
  } else {
    return invalidContract || invalidPartner
  }
})

const applyPartner = () => {
  const p = partner.value
  if (!p) {
    return
  }

  resetForm()

  name.value = p.name
  email.value = p.contact_email
  address.value = p.address
  contactName.value = p.contact_name
  phone.value = p.contact_phone
}

const unselectPartner = () => {
  partner.value = null
  resetForm()
}

const applyVendor = () => {
  const billVendor = props.billVendor

  if (!billVendor) {
    resetForm()
    return;
  }

  name.value = billVendor.name
  address.value = billVendor.address
  contactName.value = billVendor.contact_name || billVendor.name
  email.value = billVendor.email
  phone.value = billVendor.phone

  contractFormData.name = `${billVendor.name} Contract`

  // if (business.start_drop_date) {
  //   const date = business.start_drop_date
  //   const strDate = convertDate(date)
  //   contractFormData.date = strDate
  //   contractFormData.effective_date = strDate
  //   contractFormData.expiration_date = convertDate(date + 365 * 5 * 86400)
  // }
}

const resetForm = () => {
  name.value = ""
  address.value = ""
  contactName.value = ""
  email.value = ""
  phone.value = ""

  contractFormData.name = ""
  contractFormData.date = null
  contractFormData.effective_date = null
  contractFormData.expiration_date = null
  contractFormData.primary_sale_rev_share = 100
  contractFormData.secondary_sale_rev_share = 100
}

const createPartner = async () => {
  loading.value = true

  try {
    await PartnersAPI.createPartnerMatch(params.value)
    toast.success('Partner created!')
  } catch (err) {
    toast.error(getErrorMessage(err))
  }

  loading.value = false
}

const updatePartner = async () => {
  loading.value = true

  try {
    const result = await PartnersAPI.updatePartnerMatch(partner.value.id, params.value)
    toast.success('Partner updated!')
    replacePartner(result)
  } catch (err) {
    toast.error(getErrorMessage(err))
  }

  loading.value = false
}

const handleCreateClick = () => {
  if (editMode.value) {
    updatePartner()
  } else {
    createPartner()
  }
}

// const convertDate = (date) => {
//   return new Date(date * 1e3).toISOString().slice(0, 10)
// }

const editMode = computed(() => props.partner)

watch(() => props.billVendor, applyVendor)

watch(() => props.partner, applyPartner)

</script>