import {computed, Ref, ref} from "vue";
import {PaginationData, Pagy} from "/js/composables/useAxios";
import {useInfiniteScroll} from "@vueuse/core";

export const usePagination = (
  element: Ref<HTMLElement>,
  loadNextPage: (page?: number) => Promise<PaginationData<any>>
) => {

  const pagination = ref(null as Pagy | null)
  const isPaginating = ref(false)
  const isLoadingFirstPage = ref(false)

  const loadPage = async () => {
    if (hasNextPage.value) {
      try {
        if (nextPage.value) {
          isPaginating.value = true
        }

        const { pagy } = await loadNextPage(nextPage.value)
        pagination.value = pagy
        isPaginating.value = false
      } catch (err) {
        isPaginating.value = false
      }
    } else {
      isPaginating.value = false
    }
  }

  useInfiniteScroll(
    element,
    async () => {
      if (isPaginating.value) {
        return
      }

      await loadPage()
    },
    {distance: 10}
  )

  const nextPage = computed((): number | null => {
    if (!pagination.value) {
      return null
    }

    if (pagination.value.currentPage < pagination.value.totalPages && pagination.value.totalPages > 0) {
      return pagination.value.currentPage + 1
    }

    return null
  })

  const hasNextPage = computed((): boolean => {
    if (!pagination.value) {
      return true
    }

    return pagination.value.currentPage < pagination.value.totalPages && pagination.value.totalPages > 0
  })

  return {
    loadFirstPage: async () => {
      pagination.value = null
      isLoadingFirstPage.value = true
      await loadPage()
      isLoadingFirstPage.value = false
    },

    isPaginating: isPaginating,
    isLoadingFirstPage: isLoadingFirstPage
  }

}