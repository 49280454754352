import "bootstrap/dist/js/bootstrap"
import "chartkick/chart.js"

import "/js/Select2"
import "/js/DateRangePicker"
import "/js/ReportChannelConsumer"
import "/js/TableSort.js"
import "/js/HighChartsDropTuning.js"

import "https://code.highcharts.com/highcharts.js"
import "https://code.highcharts.com/highcharts-more.js"
import "https://code.highcharts.com/modules/exporting.js"
import "https://code.highcharts.com/modules/export-data.js"
import "https://code.highcharts.com/modules/accessibility.js"

import Rails from "@rails/ujs"
if (!window._rails_loaded) {
  Rails.start()
}

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import Alpine from "alpinejs";
Alpine.start()

import {createApp} from "vue";
import ItemImports from "../js/components/drop_matches/ItemImports.vue"
import PartnerMatches from "../js/components/partner_matches/PartnerMatches.vue"
import InvoiceMatches from "../js/components/invoice_matches/InvoiceMatches.vue"
import SalesExports from "../js/components/sales_exports/SalesExports.vue"
import BackgroundTasks from "../js/components/background_tasks/BackgroundTasks.vue"

import Toast from "vue-toastification"

[
  {id: "#item-imports-app", app: ItemImports},
  {id: "#partner-matches-app", app: PartnerMatches},
  {id: "#invoice-matches-app", app: InvoiceMatches},
  {id: "#sales-exports-app", app: SalesExports},
  {id: "#background-tasks-app", app: BackgroundTasks},
].forEach(el => {
  const element = document.querySelector(el.id);
  if (element !== undefined && element !== null) {
    const app = createApp(el.app);
    app.use(Toast)
    app.mount(el.id);
  }
})
