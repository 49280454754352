import $ from 'jquery/dist/jquery'
import consumer from "../channels/consumer";

$(function() {
  const element = document.querySelector('#generated-report');
  if (element !== undefined && element !== null) {
    const reportId = element.dataset.reportId

    consumer.subscriptions.create({ channel: "ReportChannel", report_id: reportId }, {
      connected: () => {
        console.log(`Connected to report channel ${reportId}`);
      },

      disconnected() {
        console.log(`Disconnected from report channel ${reportId}`);
      },

      received(data) {
        console.log(data.url);
        location.reload();
      }
    })
  }
})
