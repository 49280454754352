<template>
  <div v-if="buttons">
    <label :class="{ 'active': isChecked, [variantClass]: buttonVariant, [variantSize]: size}">
      <input
          :id="id"
          type="checkbox"
          :name="name"
          v-model="checked"
          :value="checkedValue">
      <slot/>
    </label>
  </div>

  <div v-else class="flex gap-1">
    <input
        :id="id"
        type="checkbox"
        :name="name"
        :value="checkedValue"
        v-model="checked">
    <label class="text-xs" :for="id">
      <slot/>
    </label>
  </div>
</template>

<script setup lang="ts">
import {computed, Prop, PropType, ref, watch} from "vue";
import {dynamicId} from "/js/composables/DynamicId";

const props = defineProps({
  name: {
    type: String
  },
  buttons: {
    type: Boolean
  },
  checkedValue: {
    type: [String, Number, Boolean] as PropType<string | number | boolean>,
    default: true
  },
  uncheckedValue: {
    type: [String, Number, Boolean] as PropType<string | number | boolean>,
    default: false
  },
  modelValue: {
    type: [String, Number, Boolean] as PropType<string | number | boolean>
  },
  buttonVariant: {
    type: String,
    default: 'primary'
  },
  size: {
    type: String
  }
})

const emit = defineEmits(['update:modelValue'])

const id = ref(dynamicId())

const isChecked = computed(() => {
  return props.modelValue === '1' || props.modelValue === true || props.modelValue === 1
})

const variantClass = computed(() => props.buttonVariant ? `btn btn-${props.buttonVariant}` : null)
const variantSize = computed(() => props.size ? `btn-${props.size}` : null)

const checked = computed({
  get: () => isChecked.value,
  set: (value) => {
    const emitValue = value ? props.checkedValue : props.uncheckedValue
    emit('update:modelValue', emitValue)
  }
})

</script>