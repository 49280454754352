import axios, {AxiosResponseHeaders} from "axios";

export const useAxios = axios.create();

// Alter defaults after instance has been created
useAxios.defaults.headers.common['Content-Type'] = 'application/json';
useAxios.defaults.headers.common['Accept'] = 'application/json';

export interface Pagy {
  currentPage: number
  pageItems: number
  totalPages: number
  totalCount: number
}

export interface PaginationData<T> {
  data: T
  pagy: Pagy
}

const pagy = (headers: AxiosResponseHeaders): Pagy => {
  return {
    currentPage: parseInt(headers['current-page'], 10),
    pageItems: parseInt(headers['page-items'], 10),
    totalCount: parseInt(headers['total-count'], 10),
    totalPages: parseInt(headers['total-pages'], 10)
  }
}

export async function axiosGetPaginatedJsonData<T>(url: string): Promise<PaginationData<T>> {
  try {
    const response = await useAxios.get<T>(url);
    return {
      data: response.data,
      pagy: pagy(response.headers)
    }
  } catch (error) {
    throw error
  }
}

export async function axiosGetJsonData<T>(url: string): Promise<T> {
  try {
    const response = await useAxios.get<T>(url);
    return response.data;
  } catch (error) {
    throw error
  }
}

export async function axiosPostJsonData<T>(url: string, data: Object): Promise<T> {
  try {
    const response = await useAxios.post<T>(url, data);
    return response.data;
  } catch (error) {
    throw error
  }
}

export async function axiosPatchJsonData<T>(url: string, data: Object): Promise<T> {
  try {
    const response = await useAxios.patch<T>(url, data);
    return response.data;
  } catch (error) {
    throw error
  }
}