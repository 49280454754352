import {axiosPostJsonData as post, axiosGetJsonData as get} from "/js/composables/useAxios";
import {Partner} from "/js/models/Partner";

export interface SummaryParamsArchiveReport {
  id: number
  report_params: {
    data: SummaryParamsArchiveReportData[]
  }
}

export interface SummaryParamsArchiveReportContainer {
  reports: SummaryParamsArchiveReport[]
  partners: Partner[]
}

export interface SummaryParamsArchiveReportData {
  range: string
  partner_id: number,
  sale_type: SaleType
  split: boolean
}

type SaleType = 'sale' | 'claim' | 'combined'

// create a PartnerWithDate list for a report id using the container response
export const partnersWithDateFromParams = (container: SummaryParamsArchiveReportContainer, reportId: number): PartnerWithDate[] => {
  let partnersWithDate: PartnerWithDate[] = []

  const report = container.reports.find(report => report.id === reportId)

  const groupedData = report.report_params.data.reduce((acc, data) => {
    if (!acc[data.partner_id]) {
      acc[data.partner_id] = []
    }
    acc[data.partner_id].push(data)
    return acc
  }, {} as Record<number, SummaryParamsArchiveReportData[]>)

  // create PartnerWithDate for each partner in group
  Object.keys(groupedData).forEach(partnerId => {
    const partner = container.partners.find(p => p.id === parseInt(partnerId, 10))
    const dataList = groupedData[partnerId]

    const range = dataList[0].range
    const sale = !!dataList.find(data => data.sale_type === 'sale')
    const claim = !!dataList.find(data => data.sale_type === 'claim')
    const combined = !!dataList.find(data => data.sale_type === 'combined')
    const splitCombined = !!dataList.find(data => data.sale_type === 'combined' && data.split)

    if (partner) {
      const partnerWithDate = {
        partner,
        range,
        balance: false,
        sale: sale,
        claim: claim,
        combined: combined,
        split_combined: splitCombined,
      }

      partnersWithDate.push(partnerWithDate)
    }
  })

  return partnersWithDate
}

export interface PartnerWithDate {
  partner: Partner
  range: string
  balance: boolean
  sale: boolean // generate a sales report
  claim: boolean // generate a claims report
  combined: boolean // generate a combined report
  split_combined: boolean // generate a split combined report
}

export interface Report {
  id: number
  partnerData: PartnerWithDate[]
}

export const ReportsAPI = {
  createSalesExport: async (data): Promise<{ id: number }> => {
    return await post('/api/summary_params_archives', {data})
  },

  getSalesExports: async (): Promise<SummaryParamsArchiveReportContainer> => {
    return await get(`/api/summary_params_archives`)
  }
}