<template>
  <label class="flex gap-1" :class="{ 'active': isChecked, [variantClass]: true, [variantSize]: true }">
    <input type="radio"
           :id="id"
           :name="name"
           :checked="isChecked"
           :value="currentValue"
           @input="updateValue">
    <span class="text-xs text-gray-500">{{ option.text }}</span>
  </label>
</template>

<script setup lang="ts">

import {computed, PropType, ref} from "vue";
import {dynamicId} from "/js/composables/DynamicId";
import {BFormRadioOption} from "/js/components/forms/BFormRadioOption";

const isChecked = computed(() => {
  const v = props.option.value
  if (['string', 'number'].includes(typeof v)) {
    return props.modelValue === props.option.value
  }

  if (typeof v === 'object' && props.isEqual) {
    return props.isEqual(props.modelValue, props.option.value)
  }

  return false
})

const props = defineProps({
  name: {
    type: String
  },
  modelValue: [String, Number, Object] as PropType<string | number | object>,
  option: {
    type: Object as PropType<BFormRadioOption>,
    required: true
  },
  buttonVariant: {
    type: String
  },
  size: {
    type: String
  },
  isEqual: Function
})

const id = ref(dynamicId())

const emit = defineEmits(['update:modelValue', 'change'])

const variantClass = computed(() => props.buttonVariant ? `btn btn-${props.buttonVariant}` : null)
const variantSize = computed(() => props.size ? `btn-${props.size}` : null)

// set an input html value only if string/number/boolean
const currentValue = computed(() => {
  const v = props.option.value
  if (['string', 'boolean', 'number'].includes(typeof v)) {
    return v
  }
  return null
})

const updateValue = (event) => {
  emit('update:modelValue', props.option.value)
  emit('change', props.option.value)
}
</script>