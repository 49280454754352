import {BillVendor} from "/js/models/BillVendor";
import {axiosGetJsonData, axiosPatchJsonData} from "/js/composables/useAxios";
import {makeQueryString} from "/js/composables/makeQueryString";
import {BillInvoice} from "/js/models/BillInvoice";

export const BillAPI = {
  getBillVendors: async (query: String): Promise<[BillVendor]> => {
    return await axiosGetJsonData('/api/bill_vendors?' + makeQueryString({query: query}))
  },

  markPartnerPayment: async (id: Number, partnerPayment: Boolean): Promise<BillInvoice> => {
    return await axiosPatchJsonData(`/api/invoice_matches/${id}`, {bill_invoice: {partner_payment: partnerPayment}})
  },

  updateInvoiceLine: async (id: Number, lineType: string): Promise<BillInvoice> => {
    return await axiosPatchJsonData(`/api/invoice_matches/${id}`, {bill_invoice_line: {line_type: lineType}})
  },

  updateInvoiceLineRange: async (id: Number, from: string, to: string): Promise<BillInvoice> => {
    return await axiosPatchJsonData(`/api/invoice_matches/${id}`, {bill_invoice_line: {start_date: from, end_date: to}})
  },

  getInvoices: async (partnerId: Number): Promise<[BillInvoice]> => {
    return await axiosGetJsonData(`/api/partners/${partnerId}/invoices`)
  }
}
