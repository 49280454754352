import {
  axiosGetJsonData as get,
  axiosPostJsonData as post,
} from "/js/composables/useAxios";

import {BackgroundTaskList} from "/js/models/BackgroundTask";

export const BackgroundTasksAPI = {
  getTasks: async (): Promise<BackgroundTaskList> => {
    return await get('/api/background_tasks')
  },

  createSync: async (taskType: string): Promise<void> => {
    let params = {}

    if (taskType) {
      params['task_type'] = taskType
    }

    return await post('/api/background_tasks', params)
  }
}